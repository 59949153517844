<template>
  <div class="home mb-40">
    <!-- slider -->
    <slick ref="slick" class="slickHome "  @afterChange="handleAfterChange" v-if="sliders.length"  :options="slickOptions">
      <div class="slick-content-home" v-for="(slide, i) in sliders" :key="i">
        <div
          class="sub-content-slider"
          :style="{
            'background-image':
              'url(' + slide.background + ')',
          }"
        >
          <div class="content-text">
            <span class="d-block font-36  text-white mb-15 font-weight-6">
              <template v-if="slide.title_show">{{ slide.title }}</template><template v-else><br></template>
            </span>
            <span class="d-block font-14 text-white mb-20">
              <template v-if="slide.caption_show">{{ slide.subtitle }}</template>
              <template v-else><br><br></template>
            </span>
            <template v-if="slide.action_show">
              <template v-if="slide.type == 'redirect'">
                <a :href="slide.link" target="_blank" class="btn-outline-white font-14 trasition-bounce">
                  Selengkapnya
                </a>
              </template>
              <template v-else-if="slide.type == 'download'">
                <a :href="slide.file"  class="btn-outline-white font-14 trasition-bounce">
                  Selengkapnya
                </a>
              </template>
              <template v-else>
                <a v-if="slide.page" :href="`/page/${slide.page.slug}`"  class="btn-outline-white font-14 trasition-bounce">
                  Selengkapnya
                </a>
                <a v-else href="#"  class="btn-outline-white font-14 trasition-bounce">
                  Selengkapnya
                </a>
              </template>
            </template>
          </div>
        </div>
      </div>
    </slick>
    <!-- form pencarian -->
    <section class="for-find mb-60">
      <div class="container text-center">
        <div class="head-find">
          <span>
            <div class="width-70 w-res-100">
              <i class="fa fa-search fa-flip-horizontal mr-2"></i>
              <a href="http://info.halal.go.id/cari" class="for-find-target" target="_blank" >Cari nomor sertifikat / nama produk</a>
            </div>
          </span>
        </div>
      </div>
    </section>
    <!-- about -->
    <section class="mb-60 mb-res-10">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="for-img-sec-2">
              <img
                class="img-1"
                :src="$store.state.company_profile.image_1"
                alt=""
              />
              <img
                class="img-2"
                :src="$store.state.company_profile.image_2"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-6 bg-sec-2">
            <div class="mt-45">
              <h2 class="font-24  font-res-m-16 font-weight-6 pos-relative no-margin">
                Sekilas Tentang BPJPH
                <span class="divider-min mt-2"></span>
              </h2>
              <p class="ln-2 font-16 font-res-m-16 font-weight-4 color-grey-dark" v-html="$store.state.company_profile.about"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- need to know -->
    <section class="mb-60">
      <div class="container-fluid sec-3 pt-60 pb-60 pb-res-63">
        <div class="container _sub-sec-3">
          <h2 class="font-24 font-res-m-16 font-weight-6 pos-relative mb-30 mb-res-15">
            Perlu Kamu Tahu
            <span class="divider-min mt-2"></span>
          </h2>
          <h2 class="font-14 font-weight-4 pos-relative mb-30 color-grey-dark">
            Perlu kamu tahu tentang Badan Penyelenggara Jaminan Produk Halal
            <router-link to="/perlu-kamu-tahu" class="hide-mobile float-right">
              <span class="   cursor-pointer font-weight-6 transition-bounce color-primary trasition-bounce">
                Lihat Semua
              </span>
            </router-link>
          </h2>
          <slick ref="slick" class="slickHome-sec-3" :options="slickSec3" v-if="perluTahu.length">
            <div class="item-sec" v-for="(info, index) in perluTahu" :key="index">
              <img :src="info.background" alt="" @click="redirectTo(info)" />
              <div class="for-content" v-if="info.title" @click="redirectTo(info)" >
                <h2 class="elipsis-2-char ">{{ info.title }}</h2>
              </div>
            </div>
          </slick>
              <router-link to="/perlu-kamu-tahu" class="hide-desktop" >
              <span class="float-right font-res-m-14   cursor-pointer font-weight-6 transition-bounce color-primary trasition-bounce">
                Lihat Semua
              </span>
            </router-link>
        </div>
      </div>
    </section>
    <!-- layanan -->
    <section class="pb-60">
      <div class="container">
        <h2 class="font-24 font-res-m-16 font-weight-6 pos-relative mb-30">
          Layanan
          <span class="divider-min mt-2"></span>
        </h2>
        <h2 class="font-14 font-weight-4 pos-relative mb-30 color-grey-dark ln-2">
          Berbagai layanan terbuka yang diberikan olah Badan Penyelenggara
          Jaminan Produk Halal <br />kepada masyarakat
        </h2>
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <template v-for="(service, index) in layanan">
              <div class="col-md-6 col-lg-4" :key="index">
                <div class="card-for-sec-4">
                  <div class="_content">
                    <img
                      :src="service.icon"
                      alt=""
                      style="width: 40px; height: 40px"
                    />
                    <span class="d-block  ln-c-5 font-weight-6 font-14 pt-18 pb-9 elipsis-2-char">
                      {{ service.title }}
                    </span>
                    <h2 class="   ln-c-7 font-weight-5 font-14 color-grey-dark  elipsis-4-char">
                      {{ service.subtitle }}
                    </h2>
                    <a :href="`layanan/${service.slug}`" class="trasition-bounce">Selengkapnya</a>
                  </div>
                </div>
              </div>
              </template>
            </div>
          </div>
          <div class="col-md-4">
            <h2 class="font-16 font-weight-6 pl-13 mb-50">
              Statistik Layanan 2020
            </h2>
            <div id="chart" class="chart-pie">
              <!-- <apexchart
                type="pie"
                :options="chartOptions"
                :series="series"
              ></apexchart> -->
              <Chart :chartdata="chartdata" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- informasi terbaru -->
    <section class="mb-60">
      <div class="container-fluid bg-green-transparent sec-5">
        <div class="container pt-60 pb-60">
          <h2 class="font-24 font-res-m-16 font-weight-6 pos-relative mb-30 mb-res-20">
            Informasi Terbaru
            <span class="divider-min mt-2"></span>
          </h2>
          <h2 class="font-14 font-weight-4 pos-relative mb-30   color-grey-dark ln-2">
            Informasi terbaru Badan Penyelenggara Jaminan Produk Halal
          </h2>
          <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-4 mb-res-15">
              <h2 class="font-weight-6 float-left-res w-res-50 font-16  font-res-m-14 mb-15">Pengumuman</h2>
              <router-link to="/announcement" class="float-right-res hide-desktop">
                <span class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer">
                  Lihat Lainnya
                </span>
              </router-link>
              <div class="container-scroll-responsive">
                <div class="for-responsive-scroll">
                  <template v-for="(data, index) in pengumuman">
                    <div class="item-sec-5 cursor-pointer" :key="index" @click="redirectToPost(data)">
                      <div class="_sub">
                        <h2 class="font-14 font-weight-6 elipsis-2-char post-title">
                          {{ data.title }}
                        </h2>
                        <template v-if="data.type == 'download'">
                          <a class="for-download-file" href="#">
                            <img src="@/assets/images/compro/icon/download.png" alt="">
                          </a>
                        </template>
                        <template v-else-if="data.type == 'redirect'">
                          <a class="for-link" href="#">
                            <img src="@/assets/images/compro/icon/chain.svg" alt="">
                          </a>
                        </template>
                        <template v-else-if="data.type == 'blog-post'">
                          <a class="for-link" href="#">
                            <img src="@/assets/images/compro/icon/file.svg" alt="">
                          </a>
                        </template>
                      </div>
                      <div class="for-date-watch">
                        <span class="s1">{{ data.timestamp | date_only }} | {{ data.timestamp | time_only }}</span>
                        <span class="s2">
                          <i class="fa fa-eye"></i> Dilihat {{ data.viewers}} kali
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="w-100 hide-mobile text-center">
                <router-link to="/announcement">
                  <span class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer">
                    Lihat Lainnya
                  </span>
                </router-link>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-4 mb-res-15">
              <h2 class="font-weight-6 font-16 mb-15 font-res-m-14 float-left-res w-res-50">Artikel dan Opini</h2>
              <router-link to="/article" class="float-right-res hide-desktop">
                <span class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer">
                  Lihat Lainnya
                </span>
              </router-link>
              <div class="container-scroll-responsive">
                <div class="for-responsive-scroll">
                  <template v-for="(data, index) in article">
                    <div class="item-sec-5 cursor-pointer" :key="index" @click="redirectToPost(data)">
                      <div class="_sub">
                        <h2 class="font-14 font-weight-6 elipsis-2-char post-title">
                          {{ data.title }}
                        </h2>
                        <template v-if="data.type == 'download'">
                          <a class="for-download-file" href="#">
                            <img src="@/assets/images/compro/icon/download.png" alt="">
                          </a>
                        </template>
                        <template v-else-if="data.type == 'redirect'">
                          <a class="for-link" href="#">
                            <img src="@/assets/images/compro/icon/chain.svg" alt="">
                          </a>
                        </template>
                        <template v-else-if="data.type == 'blog-post'">
                          <a class="for-link" href="#">
                            <img src="@/assets/images/compro/icon/file.svg" alt="">
                          </a>
                        </template>
                      </div>
                      <div class="for-date-watch">
                        <span class="s1">{{ data.timestamp | date_only }} | {{ data.timestamp | time_only }}</span>
                        <span class="s2">
                          <i class="fa fa-eye"></i> Dilihat {{ data.viewers}} kali
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="w-100 text-center hide-mobile">
                <router-link to="/article" >
                  <span class="d-block color-primary  trasition-bounce font-weight-6 font-14 cursor-pointer">
                    Lihat Lainnya
                  </span>
                </router-link>
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-4 mb-res-15">
              <h2 class="font-weight-6 font-16 mb-15 font-res-m-14 float-left-res">Kalender</h2>
              <router-link to="/calendar" class="float-right-res hide-desktop">
                <span class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer">
                  Lihat Lainnya
                </span>
              </router-link>
              <div class="container-scroll-responsive">
                <div class="for-responsive-scroll">
                  <template v-for="(data, index) in kalender">
                    <div class="item-sec-5" :key="index" @click="redirectToPost(data)">
                      <div class="_sub">
                        <h2 class="font-14 font-weight-6 elipsis-2-char post-title">
                          {{ data.title }}
                        </h2>
                        <template v-if="data.type == 'download'">
                          <a class="for-download-file" href="#">
                            <img src="@/assets/images/compro/icon/download.png" alt="">
                          </a>
                        </template>
                        <template v-else-if="data.type == 'redirect'">
                          <a class="for-link" href="#">
                            <img src="@/assets/images/compro/icon/chain.svg" alt="">
                          </a>
                        </template>
                        <template v-else-if="data.type == 'blog-post'">
                          <a class="for-link" href="#">
                            <img src="@/assets/images/compro/icon/file.svg" alt="">
                          </a>
                        </template>
                      </div>
                      <div class="for-date-watch">
                        <span class="s1">{{ data.timestamp | date_only }} | {{ data.timestamp | time_only }}</span>
                        <span class="s2">
                          <i class="fa fa-eye"></i> Dilihat {{ data.viewers}} kali
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="w-100 text-center hide-mobile">
                <router-link to="/calendar">
                  <span class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer">
                    Lihat Lainnya
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- berita -->
    <section class="mb-60">
      <div class="container">
        <h2 class="font-24 font-weight-6 pos-relative mb-30">
          Berita Dan Kegiatan
          <span class="divider-min mt-2"></span>
        </h2>
        <h2 class="font-14 font-weight-4 pos-relative mb-30 color-grey-dark ln-2">
          Liputan berita terbaru Badan Penyelenggara Jaminan Produk Halal
        </h2>
        <div class="row">
          <div class="container-scroll-responsive hide-mobile">
          <ul class="news-sec-6">
            <li class="_items" v-for="(ber, i) in berita" :key="i">
              <router-link :to="`/berita/${ber.slug}`">
                <div class="_sub_items">
                  <template v-if="ber.banner">
                    <img :src="ber.banner" alt="Berita">
                  </template>
                  <template v-else>
                    <img src="@/assets/images/compro/misc/default-image.jpg" alt="">
                  </template>
                  <div class="content">
                    <span class="d-block w-100  ">{{ ber.title }}</span>
                    <div class="view">
                      <span class="s1 ">{{ ber.timestamp_publish_at | date_only }} | {{ ber.timestamp_publish_at | time_only }} </span>
                      <span class="s2">
                        <i class="fa fa-eye"></i> Dilihat {{ ber.viewers }} kali
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
          </div>
        <ul class="news-sec-6 hide-desktop">
           <slick ref="slick" class="slickHome-sec-3" :options="slickSec3" v-if="berita.length">
                <li class="_items" v-for="(ber, i) in berita" :key="i">
              <router-link :to="`/berita/${ber.slug}`">
                <div class="_sub_items">
                  <template v-if="ber.banner">
                    <img :src="ber.banner" alt="Berita">
                  </template>
                  <template v-else>
                    <img src="@/assets/images/compro/misc/default-image.jpg" alt="">
                  </template>
                  <div class="content">
                    <span class="d-block w-100  ">{{ ber.title }}</span>
                    <div class="view">
                      <span class="s1 ">{{ ber.timestamp | date_only }} | {{ ber.timestamp | time_only }} </span>
                      <span class="s2">
                        <i class="fa fa-eye"></i> Dilihat {{ ber.viewers }} kali
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </li>
           </slick>
        </ul>
        <div class="w-100 text-center">
          <router-link to="/berita">
            <span style="margin-right: 10px" class="d-block float-right-res mt-res-10 pr-res-20 color-primary trasition-bounce font-weight-6 font-14 cursor-pointer">
              Lihat Lainnya
            </span>
          </router-link>
        </div>
        </div>
      </div>
    </section>
    <!-- seputar plugin -->
    <section class="mb-60">
      <div class="container">
        <h2 class="font-24 font-weight-6 pos-relative mb-30 font-res-m-16">Seputar BPJPH</h2>
        <h2 class="font-14 font-weight-4 pos-relative mb-30 color-grey-dark ln-2">
          Seputar Badan Penyelenggara Jaminan Produk Halal
        </h2>
        <div class="row">
          <div class="col-md-4 mb-res-15 mb-10">
            <div class="items-sec-7">
              <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbpjphkemenagri&tabs=timeline&width=394&height=464&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=135517754666448" width="100%" height="464" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
          </div>
          <div class="col-md-4 mb-res-15 mb-10">
            <div class="items-sec-7">
              <twitter> 
                <a class="twitter-timeline" href="https://twitter.com/bpjphkemenag?ref_src=twsrc%5Etfw">Tweets by bpjphkemenag</a> 
              </twitter>
            </div>
          </div>
          <div class="col-md-4 mb-res-15 mb-10">
            <div class="items-sec-7 ">
              <div class="w-100">
                <template v-for="(media, index) in instagrams">
                  <img v-if="media.media_type == 'VIDEO'" v-bind:key="index" class="p-1" style="width: 33.33%; display: inline" :src="media.thumbnail_url"  alt="">
                  <img v-else v-bind:key="index" class="p-1" style="width: 33.33%; display: inline" :src="media.media_url" alt="">
                </template>
              </div>
              <button class="btn btn-primary btn-block" type="button" v-if="instagramsNextContent" @click="getInstagramMedia(instagramsNextContent)">Load More</button>
            </div>
          </div>
          <div class="col-md-7 mt-30 mb-10" v-if="youtubes.length">
            <div class="for-play-news">
              <iframe width="100%" height="381" :src="youtubesPreview.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-md-5 mt-30 mb-10">
            <div class="for-scroll"  >
              <div class="do-item" v-for="(item, index) in youtubes " :key="index">
                <div class="items curso-pointer" @click.prevent="youtubesPreview = item">
                  <img
                    class="for-news"
                    :src="item.thumbnail"
                    alt=""
                  />
                  <img
                    class="play-btn"
                    src="@/assets/images/compro/icon/play-btn.svg"
                    alt="play"
                  />
                </div>
                <div class="content curso-pointer">
                  <span class="" @click.prevent="youtubesPreview = item">{{ item.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- gallery -->
    <section class="mb-60">
      <div class="container">
        <h2 class="font-24 font-weight-6 pos-relative mb-30">Galeri BPJPH</h2>
        <h2 class="font-14 font-weight-4 pos-relative mb-30 color-grey-dark ln-2">
          Galeri Badan Penyelenggara Jaminan Produk Halal
           <router-link to="/galLery" class="hide-mobile float-right">
              <span class="   cursor-pointer font-weight-6 transition-bounce color-primary trasition-bounce">
                Lihat Semua
              </span>
            </router-link>
        </h2>
        
      </div>
      <div class="last-section">
        <ul>
          <li  class="image cursor-pointer"
            v-for="(gallery, index) in galleries"
            :key="index"
            @click="showedGallery = index"
          >
            <template v-if="gallery.thumb !== undefined">
               <img class="for-video-thumb" src="@/assets/images/compro/icon/play-btn.svg" alt="">
              <img :src="gallery.thumb" alt="" />
            </template>
            <template v-else>
             
              <img :src="gallery.src" alt="" /> 
            </template>
          </li>
        </ul>
      </div>
      <h2 class="text-center mt-25 hide-desktop">
        <router-link to="/gallery" class="hide-desktop  ">
              <span class="   cursor-pointer font-weight-6 transition-bounce color-primary trasition-bounce">
                Lihat Semua
              </span>
            </router-link>
      </h2>
     
    </section>
    <CoolLightBox 
      :items="galleries" 
      :index="showedGallery"
      @close="showedGallery = null">
    </CoolLightBox>
  </div>
</template>
 
<script>
import axios from 'axios';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css' 
//import VueApexCharts from "vue-apexcharts";
import Slick from "vue-slick";
import { twitter } from 'vue-twitter'
//  const getYoutubeTitle = require('get-youtube-title')
//  import getYouTubeID from 'get-youtube-id';
import Chart from './Chart.vue';

export default {
  name: "home",
  components: {
    Slick,
    CoolLightBox, 
    twitter,
    //apexchart: VueApexCharts,
    Chart
  },
  data() {
    return {
      chartdata: {
        labels: [
          "Sertifikasi Halal",
          "Registrasi Sertifikasi Halal Luar Negeri",
          "Registrasi Auditor Halal",
          "Surat Ket. Akreditasi & Registrasi LPH",
          "Sertifikat Akreditas LPH"
        ],
        datasets: [
          {
            backgroundColor: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0'],
            data: [44, 55, 41, 17, 15]
          }
        ]
      },
      idSelected:"bsa1NOOgB0U",
      apiTovideo:[
        {
          source:'https://www.youtube.com/watch?v=o-JIN4k7etk'
        },{
          source:'https://www.youtube.com/watch?v=2MYL-gmNjic'
        },{
          source:'https://www.youtube.com/watch?v=bsa1NOOgB0U'
        },{
          source:'https://www.youtube.com/watch?v=bsa1NOOgB0U'
        },{
          source:'https://www.youtube.com/watch?v=bsa1NOOgB0U'
        },
      ],
      arrayOfVideos : [],
      perluTahu: [],
      layanan: [],

      berita: [],
      article: [],
      pengumuman: [],
      kalender: [],
      galleries: [],
      showedGallery: null,
      series: [44, 55, 41, 17, 15],
      chartOptions: {
        donut: {
          size: "100%",
        },
        
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 4,
          dashArray: 0,
        },
        labels: [
          "Sertifikasi Halal",
          "Registrasi Sertifikasi Halal Luar Negeri",
          "Registrasi Auditor Halal",
          "Surat Ket. Akreditasi & Registrasi LPH",
          "Sertifikat Akreditas LPH",
        ],
        legend: {
          position: "bottom",
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "9px",

            fontWeight: "bold",
          },
          formatter: function (val) {
            return val.toFixed(0);
          },
          background: {
            borderWidth: 0,
            padding: 0,
          },
        },
        responsive: [
          {
            breakpoint: 780,
            options: {
              chart: {
                width: 400,
              },
              legend: {
                position: "bottom",
                width: 200,
              },
            },
          },
        ],
      },
      trigerSHow: false,
      slickSec3: {
        autoplay: false,
        centerMode: false,
        slidesToShow: 4,
        autoplaySpeed: 5000,
        dots: false,
        arrows: true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
                customPaging: function(slick,index) {
        return '<a data-at="'+index + 1 +'"></a>';
    },
         responsive: [ 
    {
      breakpoint: 780,
      settings: {
        dots: true,
              arrows: false,
         slidesToShow: 1,
            centerMode: true,
            variableWidth: true
      }
    },
    {
      breakpoint: 480,
      settings: {
           dots: true,
                 arrows: false,
            slidesToShow: 1,
               centerMode: true,
               variableWidth: true
      }
    }
  ]
      },
      slickOptions: {
        autoplay: false,
        autoplaySpeed: 5000,
        dots: false,
        arrows: true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
            customPaging: function(slick,index) {
        return '<a data-at="'+index + 1 +'"></a>';
    },
        // Any other options that can be got from plugin documentation
    responsive: [ 
    {
      breakpoint: 600,
      settings: {
         dots: true,
         arrows: false,
          
      }
    },
    {
      breakpoint: 480,
      settings: {

        arrows: false,
          dots: true,
      }
    } 
  ]
      },
      sliders: [],
      instagramToken: null,
      instagrams: [],
      instagramsNextContent: false,
      youtubes: [],
      youtubeChannelId: null,
      youtubesPreview: {}
    };
  },
  async mounted() {
    this.$store.commit("SET_BG", false);
    this.getslideData();
    this.getInfo();
    this.getServices();
    this.getBerita()
    this.getArticle('announcement')
    this.getArticle('article')
    this.getArticle('calendar')
    this.getGallery();
    //this.getArrtoVideo();
    this.instagramToken = await this.getSettingInstagram()
    this.getInstagramMedia()
    this.youtubeChannelId = await this.getSettingYoutube()

    if (this.youtubeChannelId) {
      const playlists = await this.getYoutubePlaylist();
      playlists.forEach( playlist => {
        this.getYoutubePlaylistItem(playlist)
      })
    }
  },
  
            //  'img': 'https://img.youtube.com/vi/'+ id +'/0.jpg'
  methods:{
    changefunction(valid){
      this.idSelected = valid
    },
    // getArrtoVideo(){
    //  var id = getYouTubeID("http://www.youtube.com/watch?v=9bZkp7q19f0");

    // var arrtoID = []
    //   this.apiTovideo.forEach( async element => { 
    //       arrtoID.push(
    //         {
    //          'id' : getYouTubeID(element.source),
    //         })
    //   });
    //   arrtoID.forEach(  element => { 
    
    //       getYoutubeTitle( element.id , (err, title) =>   {
    //         this.arrayOfVideos.push({
    //           'id': element.id,
    //           'title': title 
    //         })
    //       })
    //   })
      
    // },
    handleAfterChange(slick, currentSlide) {
      // console.log( currentSlide, slick);
      if (currentSlide.currentSlide == 0) {
        this.slideOff = true;
      } else {
        this.slideOff = false;
      }
    },
    async getSettingInstagram() {
      return await this.axios.get('/setting/instagram_account_token')
        .then( response => {
          return response.data.result.setting.value
        })
    },
    async getSettingYoutube() {
      return await this.axios.get('/setting/youtube_channel_id')
        .then( response => {
          return response.data.result.setting.value
        })
    },
    getInstagramMedia(url = 'https://graph.instagram.com/me/media?fields=media_type,media_url,thumbnail_url,caption') {
      axios.get(url, {
        params: {
          access_token: this.instagramToken
        }
      }).then( response => {
        this.instagrams = [...this.instagrams, ...response.data.data]
        if (response.data.paging.next !== undefined) {
          this.instagramsNextContent = response.data.paging.next;
        } else{
          this.instagramsNextContent = false
        }
      })
    },
    async getYoutubePlaylist() {
      return await axios.get("https://youtube.googleapis.com/youtube/v3/playlists",{
        params: {
          key: process.env.VUE_APP_YOUTUBE_API_KEY,
          channelId: this.youtubeChannelId,
          part: "id"
        }
      })
      .then( response => {
        return response.data.items.map( item => item.id);
      })
    },
    getYoutubePlaylistItem(playlistId) {
      axios.get("https://youtube.googleapis.com/youtube/v3/playlistItems",{
        params: {
          key: process.env.VUE_APP_YOUTUBE_API_KEY,
          playlistId: playlistId,
          part: "contentDetails,snippet"
        }
      })
      .then( response => {
        const items = response.data.items
        items.forEach( item => {
          if (item.snippet.thumbnails.standard !== undefined) {
            this.youtubes.push({
              title: item.snippet.title,
              thumbnail: item.snippet.thumbnails.standard.url,
              video: `https://www.youtube.com/embed/${item.contentDetails.videoId}`
            })

            if (this.youtubes.length && !this.youtubesPreview.hasOwnProperty("video")) {
              this.youtubesPreview = this.youtubes[0]
            }
          }
        })
      })
    },
    getslideData() {
      this.axios.get('slider', {
        params: {
          status: 1,
          page: 0,
          limit: 100,
          sortBy: 'created_at',
          sortType: 'DESC'
        }
      })
        .then( response => {
           this.sliders = response.data.result.sliders;
        })
    },
    getServices() {
      this.axios.get('service', {
        params: {
          status: 1,
          page: 1,
          limit: 6,
          featured: 1
        }
      })
        .then( response => {
          this.layanan = response.data.result.services
        })
    },
    getInfo() {
      this.axios.get('need-to-know', {
        params: {
          status: 1,
          page: 1,
          limit: 6,
          sortBy: 'created_at',
          sortType: 'DESC'
        }
      })
        .then( response => {
          this.perluTahu = response.data.result.knows
        })
    },
    redirectTo(info) {
      if (info.type == 'redirect') {
        window.open(info.link, '_blank');
      } else if (info.type == 'download') {
        window.location = info.file
      } else if (info.page){
        this.$router.push(`/page/${info.page.slug}`)
      }
    },
    getBerita() {
      this.axios.get(`news`, {
        params: {
          page: 1,
          limit: 5,
          status: 1,
          sortBy: 'publish_at',
          sortType: 'DESC',
        }
      }).then( response => {
        this.berita = response.data.result.news
      })
    },
    getArticle(category) {
      this.axios.get('post', {
        params: {
          status: 1,
          category: category,
          page: 1,
          limit: 5,
          pined: 1
        }
      })
        .then( response => {
          if (category == 'article') {
            this.article = response.data.result.posts
          } else if(category == 'announcement'){
            this.pengumuman = response.data.result.posts
            //console.log(this.pengumuman)
          } else {
            this.kalender = response.data.result.posts
          }
          this.rows = response.data.result.total
        })
    },
    redirectToPost(info) {
      if (info.type == 'redirect') {
        window.open(info.link, '_blank');
      } else if (info.type == 'download') {
        window.open(info.file, '_blank');
      } else {
        this.$router.push(`/publikasi/${info.slug}`)
      }
    },
    getGallery() {
      this.axios.get(`gallery`, {
        params: {
          page: 1,
          limit: 10,
          status: 1,
          sortBy: 'created_at',
          sortType: 'DESC',
        }
      }).then( response => {
        this.galleries = response.data.result.galleries.map( item => {
          const gallery = {
            title: item.title,
            src: item.file,
          }
          if (item.type == 'video') {
            gallery.thumb = item.video_thumbnail
          } 

          return gallery
        })
        //console.log(this.galleries)
      })
    }
  },
};
</script>

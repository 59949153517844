<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    chartdata: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        align: 'start'
      },
    }
  }),
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>